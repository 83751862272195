import React from 'react';
import { graphql } from 'gatsby';
import Press from '../templates/press';
import { footerCtaFragment } from '../components/Footer';
import { linkFragmentQuery } from '../templates/page';

/**
 * This component is used to display preview for an unpublished page. Previews for published pages
 * will go directly to that page, but unpublished pages need a placeholder page (like this) to be
 * able to view them. Our link resolver checks to see if we're previewing an unpublished page and
 * redirects to this page - once here, we just render the page component with the preview query
 * which is mostly the same as the regular page query, just with any references to `Sharp` image
 * fields being removed. Since the Prismic ref for the previewable content is stored in a cookie,
 * the application uses that to retrieve the unpublished content for preview in the query.
 *
 * @param {object} props Component props.
 */
const PressPreview = props => <Press {...props} />;

PressPreview.fragments = [footerCtaFragment, linkFragmentQuery];

export const query = graphql`
  query PressPreviewQuery($uid: String) {
    prismic {
      allPresss(uid: $uid) {
        edges {
          node {
            title
            content
            featured_image
          }
        }
      }

      allPress_archive_pages {
        edges {
          node {
            cta {
              ...FooterCta
            }
          }
        }
      }
    }
  }
`;

export default PressPreview;
