import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import styles from './TagList.module.scss';

const TagList = ({ tags }) => {
  if (tags.length === 0) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h4 className={styles.heading}>Related Topics</h4>

        {tags.map(tag => (
          <Link
            to={`/news-and-media/tag#${encodeURIComponent(tag)}`}
            key={tag}
            className={styles.tag}>
            {tag}
          </Link>
        ))}
      </div>
    </div>
  );
};

TagList.propTypes = {
  tags: PropTypes.array,
};

TagList.defaultProps = {
  tags: [],
};

export default TagList;
