import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from '../components/SEO';
import Header from '../components/BasicPage/Header';
import Body from '../components/BasicPage/Body';
import NotFoundPage from '../pages/404';
import Footer, { footerCtaFragment } from '../components/Footer';
import moment from 'moment';
import { linkFragmentQuery } from '../templates/page';
import TagList from '../components/TagList';

const Press = ({ data, location }) => {
  const article = data.prismic.allPresss.edges.slice(0, 1).pop();
  if (!article) {
    return <NotFoundPage />;
  }

  const pressArchive = data.prismic.allPress_archive_pages.edges.slice(0, 1).pop();
  const {
    title,
    content,
    publish_date,
    featured_image,
    featured_imageSharp,
    featured_embed_video,
    _meta,
    seo,
  } = article.node;

  return (
    <>
      <SEO
        title={RichText.asText(title)}
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />
      <Header
        title={title}
        embedVideo={featured_embed_video}
        image={featured_image}
        sharpImage={featured_imageSharp}
        overline={moment(publish_date).format('MM.DD.YYYY')}
      />
      <Body content={content} cta={pressArchive && pressArchive.node.cta} dropcap={false} />
      {_meta && _meta.tags ? <TagList tags={_meta.tags} /> : null}
      <Footer />
    </>
  );
};

Press.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allPresss: PropTypes.object,
      allPress_archive_pages: PropTypes.object,
    }),
  }),
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
};

Press.fragments = [footerCtaFragment, linkFragmentQuery];

export const query = graphql`
  query IDPressQuery($uid: String) {
    prismic {
      allPresss(uid: $uid) {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }
            title
            content
            publish_date
            featured_image
            featured_imageSharp {
              ...FeaturePhotoQuery
            }
            featured_embed_video
            _meta {
              tags
            }
          }
        }
      }
      allPress_archive_pages {
        edges {
          node {
            cta {
              ...FooterCta
            }
          }
        }
      }
    }
  }
`;

export default Press;
